import { AuthConfig } from '@auth0/auth0-angular'

export const Auth0Environment: AuthConfig = {
    clientId: 'N6WsLddUC32JFlYt9aukcSOdn4Ou5ccz',
    domain: 'login.poweredbyliquid.com',
    redirectUri: 'https://reef.poweredbyliquid.com/auth-callback',
    audience: 'https://api.poweredbyliquid.com',
    scope: 'read:current_user',
    httpInterceptor: {
        allowedList: [
            {
                uriMatcher: (uri) => uri.indexOf('oauth/token') >= 0,
                tokenOptions: {
                    audience: 'https://liquid-pd.us.auth0.com/api/v2/',
                    scope: 'read:current_user',
                },
            },
            {
                uriMatcher: (uri) => {
                    return uri.indexOf('oauth/token') < 0 && uri.indexOf('youUp') < 0 // don't add bearer token if youUp
                },
                tokenOptions: {
                    audience: 'https://api.poweredbyliquid.com',
                    scope: 'read:current_user',
                },
            },
        ],
    },
}

export const environment: any = {
    production: true,
    host: 'https://reef.poweredbyliquid.com',
    liquidApiSettings: {
        apiServicePrefix: 'https://api.poweredbyliquid.com',
        orgAvatarPrefix: 'https://org-img.poweredbyliquid.com',
        userAvatarPrefix: 'https://user-img.poweredbyliquid.com',
    },
    liquidAppHost: 'https://app.poweredbyliquid.com',
    logging: {
        apiKey: 'f19bc42dcfd0fe715f9f7af24c28acbf',
        consoleBreadcrumbsEnabled: true,
        releaseStage: 'prod',
    },
    internalEmailAdress: {
        support: 'support@poweredbyliquid.com',
    },
}
