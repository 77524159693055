import { Clipboard } from '@angular/cdk/clipboard'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { AddressService } from '../address'
import { AdminBusinessDetail, AdminBusinessProperties, AdminKycDocument, AdminPaymentAccount, AdminTransaction, AdminUserRow, BeneficialOwner, BeneficialOwnerRow, DefaultLabel, KycDocument, PaymentAccountType, Tab, TableId } from '../core/models'
import { BusinessService, PaymentAccountService, TabService, UrlService, UserService } from '../core/services'
import { ConfirmComponent, DialogService, DialogTemplateType } from '../dialogs'
import { ListField } from '../table/models'

import { EditFlagsDialogComponent } from './edit-flags-dialog/edit-flags-dialog.component'
import { EditFxRateDialogComponent } from './edit-fx-rate-dialog/edit-fx-rate-dialog.component'
import { EditOwnerDialogComponent } from './edit-owner-dialog/edit-owner-dialog.component'
import { KycDocumentDetailsDialogComponent } from './kyc-document-details-dialog/kyc-document-details-dialog.component'
import { PaymentDetailsDialogComponent } from './payment-details-dialog/payment-details-dialog.component'
import { UnmaskDialogComponent } from './unmask-dialog/unmask-dialog.component'

interface PaymentAccountRow extends AdminPaymentAccount {
    activeIndicator: string
    unmaskAccountNumbers: string
}

@Component({
    selector: 'lqd-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnDestroy, OnInit {

    private kycDocs: BehaviorSubject<AdminKycDocument[]> = new BehaviorSubject([])
    private invoices: BehaviorSubject<AdminTransaction[]> = new BehaviorSubject([])
    private owners: BehaviorSubject<BeneficialOwnerRow[]> = new BehaviorSubject([])
    private paymentAccounts: BehaviorSubject<PaymentAccountRow[]> = new BehaviorSubject([])
    private unsubscribe$: Subject<void> = new Subject<void>()
    private users: BehaviorSubject<AdminUserRow[]> = new BehaviorSubject([])

    readonly businessLogsId: string = TableId.businessLogs
    businessDetail: AdminBusinessDetail
    canRetryPayment: boolean = false

    pendingTotal: number = undefined
    plaidBalance: number = undefined
    plaidLoading: boolean = false

    readonly kycColumns: Array<ListField> = [
        {
            id: 'originalFilename',
            label: 'Document Name',
            type: 'string',
        },
        {
            id: 'description',
            label: 'Description',
            type: 'string',
        },
        {
            id: 'download',
            label: '',
            type: 'download',
        },
    ]
    readonly kycDocs$: Observable<Array<AdminKycDocument>> = this.kycDocs.asObservable()
    readonly kycTableId: string = TableId.kycDocuments
    readonly invoices$: Observable<Array<AdminTransaction>> = this.invoices.asObservable()
    readonly invoiceColumns: ListField[] = [
        {
            id: 'created',
            label: 'Date Created',
            type: 'date',
        },
        {
            id: 'invoiceNumberColumn',
            label: 'Invoice #',
            type: 'router-link',
        },
        {
            id: 'amount',
            label: 'Amount',
            type: 'money',
            notSortable: true,
        },
        {
            id: 'clientColumn',
            label: 'Client',
            type: 'router-link',
        },
        {
            id: 'vendorColumn',
            label: 'Vendor',
            type: 'router-link',
        },
        {
            id: 'paymentStatus',
            label: 'Status',
            notSortable: true,
        },
        {
            id: 'datePaid',
            label: 'Date Paid',
            type: 'date',
        },
        {
            id: 'fxEquivalent',
            label: 'FX Equivalent',
        },
    ]
    readonly ownerColumns: Array<ListField> = [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'displayAddress',
            label: 'Address',
        },
        {
            id: 'ssn',
            label: 'SSN #',
        },
        {
            id: 'remove',
            label: '',
            type: 'remove',
        },
    ]
    readonly owners$: Observable<Array<BeneficialOwnerRow>> = this.owners.asObservable()
    readonly ownersTableId: string = TableId.beneficialOwners
    readonly paymentAccounts$: Observable<Array<PaymentAccountRow>> = this.paymentAccounts.asObservable()
    readonly paymentColumns: Array<ListField> = [
        {
            id: 'createdTimestamp',
            label: 'Created On',
            type: 'timestamp',
        },
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'last4',
            label: 'Bank Account Number',
        },
        {
            id: 'routingNumberAbaLast4',
            label: 'Routing Number (ABA)',
        },
        {
            id: 'paymentAccountType',
            label: 'Type',
        },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'createdBy',
            label: 'Created By',
        },
        {
            id: 'deactivatedBy',
            label: 'Deactivated By',
        },
        {
            id: 'activeIndicator',
            label: 'Active',
            type: 'icon',
            notSortable: true,
        },
        {
            id: 'bankExistsInDatabase',
            label: 'Foreign Bank',
            type: 'icon',
            notSortable: true,
            getValue(item: PaymentAccountRow): string {
                if (item.paymentAccountType !== PaymentAccountType.OtherBankAccount || item.bankId) {
                    return !item.hasBankInfoOverride ? 'check-circle' : 'alert-circle'
                }
                return 'alert-triangle'
            },
            getTooltip(row: PaymentAccountRow): string {
                if (row.paymentAccountType !== PaymentAccountType.OtherBankAccount || row.bankId) {
                    return !row.hasBankInfoOverride ? 'All good' : `Bank information has been overridden, please review in 'Foreign Bank Review' section on Reef homepage`
                }
                return 'There is no bank with such a swift code in the database.'
            },
        },
        {
            id: 'unmaskAccountNumbers',
            label: 'Unmask',
            checkButtonIcon: 'eye-off',
            type: 'check-button',
            notSortable: true,
            checkButtonClass: (row: PaymentAccountRow, col: ListField): string => {
                if (!row.last4.toString().startsWith('*')) {
                    return 'error'
                } else {
                    return 'success'
                }
            },
        },
    ]
    readonly paymentsTableId: string = TableId.paymentAccounts
    unmaskedSsnOrEin: string
    readonly userColumns: Array<ListField> = [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'roles',
            label: 'Roles',
            type: 'list',
        },
        {
            id: 'dateJoined',
            label: 'Date Joined',
            type: 'date',
        },
        {
            id: 'getImpLink',
            label: 'Impersonate',
            checkButtonIcon: 'link',
            type: 'check-button',
            notSortable: true,
        },
    ]
    readonly users$: Observable<Array<AdminUserRow>> = this.users.asObservable()
    readonly usersTableId: string = TableId.businessUsers

    businessFunction: FormControl = new FormControl()

    get isClient(): boolean { return this.businessDetail?.businessType === 'Client' }
    get disablePlaid(): boolean { return this.plaidBalance !== undefined && this.plaidBalance < 0 }

    constructor(
        private addresses: AddressService,
        private paymentAccountService: PaymentAccountService,
        private business: BusinessService,
        private clipboard: Clipboard,
        private dialogs: DialogService,
        private route: ActivatedRoute,
        private tabService: TabService,
        private urls: UrlService,
        private userSvc: UserService,
    ) { }

    ngOnInit(): void {

        this.addresses.initialize().subscribe()

        this.getAndSetBusiness()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    addDummyAccount(businessId: string): void {
        this.business.addDummyAccount(businessId)
            .pipe(
                switchMap(() => this.business.getBusiness(this.businessDetail.id)),
                tap((biz) => {
                    this.businessDetail = biz
                    this.paymentAccounts.next(this.businessDetail.paymentAccounts.map(account => this.getPaymentAccountRow(account)))
                }),
            )
            .subscribe()
    }

    changeBizFunction(properties: { isHirer: boolean, isWorker: boolean }): void {

        const req: AdminBusinessProperties = {
            organizationId: this.businessDetail.id,
            properties,
        }

        this.dialogs.wait()
        this.business.updateBusinessProperties(req)
            .pipe(
                tap(result => {
                    this.businessDetail = result
                    this.dialogs.close()
                }),
            )
            .subscribe()
    }

    kycStatusUpdate(): void {
        this.getBusiness()
    }

    onCheckButtonClick(event: { field: ListField, row: PaymentAccountRow }): void {
        if (!(event.field.id === 'unmaskAccountNumbers' && event.row.unmaskAccountNumbers)) {
            return
        }

        const currentAccountNumber: string = event.row.last4.toString()
        const currentRoutingNumber: string = event.row.routingNumberAbaLast4?.toString()

        if (!currentAccountNumber.startsWith('*')) {
            // Mask
            event.row.last4 = this.maskFullValue(currentAccountNumber)
            event.row.routingNumberAbaLast4 = this.maskFullValue(currentRoutingNumber)
            return
        }

        // Unmask
        const dialogConfig: MatDialogConfig = {
            data: {
                paymentAccount: <AdminPaymentAccount>event.row,
            },
        }

        this.dialogs.open(UnmaskDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap((unmaskedValues: { [key: string]: string }) => {
                    if (unmaskedValues && Object.keys(unmaskedValues).length > 0) {
                        event.row.last4 = unmaskedValues.accountNumber ?? (unmaskedValues[DefaultLabel.BankAccountNumber] ?? unmaskedValues[DefaultLabel.Iban])
                        event.row.routingNumberAbaLast4 = unmaskedValues.routingNumber
                    }
                }),
            )
            .subscribe()
    }

    popRetrySubscriptionCharges(): void {

        this.dialogs.confirm('Retry subscription charges for this business?', 'Yes',
            `Retry Subscription Charges`, 'No')
            .pipe(
                filter(confirmation => !!confirmation),
                switchMap(() => {
                    return this.business.subscriptionBillingRetry(this.businessDetail.id)
                }),
                tap(r => {
                    this.dialogs.inform(`${r.message}`, r.successful ? 'Subscription Charge Retry Successful' : 'There Was An Error!', 'OK')
                }),
            )
            .subscribe()
    }

    popCustomRate(): void {
        const dialogConfig: MatDialogConfig = {
            data: {
                rate: this.businessDetail.customFXRate,
            },
        }

        this.dialogs.open(EditFxRateDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter(newRate => !!newRate),
                switchMap((newRate) => {
                    this.dialogs.wait()
                    return this.business.updateFXRate(this.businessDetail.id, newRate)
                }),
                tap((res) => {
                    this.businessDetail.customFXRate = res
                    this.dialogs.close()
                })
            )
            .subscribe()
    }

    popEditFlags(): void {

        const dialogConfig: MatDialogConfig = {
            data: {
                biz: this.businessDetail,
            },
        }

        this.dialogs.open(EditFlagsDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter(updatedBiz => !!updatedBiz),
                switchMap((updatedBiz) => {
                    this.dialogs.wait()
                    return this.business.getBusiness(updatedBiz.id)
                }),
                tap(biz => {
                    this.businessDetail = biz
                    this.paymentAccounts.next(this.businessDetail.paymentAccounts.map(account => this.getPaymentAccountRow(account)))
                    this.dialogs.close()
                }),
            )
            .subscribe()
    }

    popUnmaskSsnOrEinOrHide(): void {

        if (this.unmaskedSsnOrEin) {
            this.unmaskedSsnOrEin = undefined
            return
        }

        const dialogConfig: MatDialogConfig = {
            data: {
                biz: this.businessDetail,
            },
        }

        this.dialogs.open(UnmaskDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap(unmaskedValue => {
                    this.unmaskedSsnOrEin = unmaskedValue
                }),
            )
            .subscribe()
    }

    popKycDocDetails(kycDoc: AdminKycDocument): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = kycDoc

        this.dialogs.open(KycDocumentDetailsDialogComponent, dialogConfig)
    }

    popPaymentDetails(account: PaymentAccountRow): void {
        // Mask if unmasked
        const currentAccountNumber: string = account.last4.toString()
        const currentRoutingNumber: string | undefined = account.routingNumberAbaLast4?.toString()
        if (!currentAccountNumber.startsWith('*')) {
            account.last4 = this.maskFullValue(currentAccountNumber)
        }
        if (!currentRoutingNumber?.startsWith('*')) {
            account.routingNumberAbaLast4 = this.maskFullValue(currentRoutingNumber)
        }
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = <AdminPaymentAccount>account

        this.dialogs.open(PaymentDetailsDialogComponent, dialogConfig)
    }

    editOwner(owner: BeneficialOwner): void {

        const dialogConfig: MatDialogConfig = {
            data: {
                owner,
                bizId: this.businessDetail.id,
            },
        }

        this.dialogs.open(EditOwnerDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter(updatedOwner => !!updatedOwner),
                tap(updatedOwner => this.updateOwner(updatedOwner)),
            )
            .subscribe()
    }

    removeOwner(ownerId: string): void {
        this.business.deleteOwner(ownerId)
            .pipe(
                tap(() => {
                    const deletedIndex: number = this.businessDetail.beneficialOwners.findIndex(owner => owner.id === ownerId)
                    this.businessDetail.beneficialOwners.splice(deletedIndex, 1)
                    const rows: Array<BeneficialOwnerRow> = this.businessDetail.beneficialOwners.map(owner => this.getOwnerRow(owner))
                    this.owners.next(rows)
                }),
            )
            .subscribe()
    }

    addOwner(owner: BeneficialOwner): void {
        this.businessDetail.beneficialOwners.push(owner)
        this.owners.next(this.businessDetail.beneficialOwners.map(o => this.getOwnerRow(o)))
    }

    updateOwner(owner: BeneficialOwner): void {
        const ownerIndex: number = this.businessDetail.beneficialOwners.findIndex(o => o.id === owner.id)
        this.businessDetail.beneficialOwners[ownerIndex] = owner
        this.owners.next(this.businessDetail.beneficialOwners.map(o => this.getOwnerRow(o)))
        this.getBusiness()
    }

    addKycDoc(doc: KycDocument): void {
        this.businessDetail.kycDocuments.push(doc)
        this.kycDocs.next(this.businessDetail.kycDocuments)
    }

    downloadDoc(doc: KycDocument): void {
        this.business.downloadDoc(doc)
            .subscribe()
    }

    addManualUSBankAccount(account: AdminPaymentAccount): void {
        const paymentAccountsCount: number = this.businessDetail.paymentAccounts.length
        const paymentAccountsReversed: Array<AdminPaymentAccount> = [...this.businessDetail.paymentAccounts].reverse()
        const lastDeactivatedIndex: number = paymentAccountsReversed.findIndex(
            x => x.paymentAccountType === PaymentAccountType.LiquidBankAccount &&
                x.deactivatedBy !== 'NA')

        // If needed set dectivated by only temporarly to previous user profile that
        // deactivated any LiquidBank (Manual US Bank Account), this is quickly updated
        // once this.getBusiness(true) gets correct details from APIs.
        if (lastDeactivatedIndex !== -1) {
            this.businessDetail.paymentAccounts[paymentAccountsCount - 1].deactivatedBy = paymentAccountsReversed[lastDeactivatedIndex].deactivatedBy
        }
        this.businessDetail.paymentAccounts.push(account)
        this.paymentAccounts.next(this.businessDetail.paymentAccounts.map(o => this.getPaymentAccountRow(o)))
        this.getBusiness(true)
        this.dialogs.inform(`Manual US Bank Account added, please check 'Payment Accounts:' table.`, 'Success')
    }

    private formatMaskedValue(maskedValue: string): string {
        return `*****${maskedValue}`
    }

    onUserCheckButtonClick(item: any): void {
        if (item.field.id === 'getImpLink') {
            this.getImpersonationLink(item)
        }
    }

    getImpersonationLink(item: any): void {
        const liquidProfileId = item.row.id
        this.userSvc.getImpersonationLink(liquidProfileId)
            .pipe(
                take(1),
                filter(result => !!result),
                tap((linkResponse: any) => {
                    const dialogConfig: MatDialogConfig = new MatDialogConfig()
                    dialogConfig.disableClose = true
                    dialogConfig.data = {
                        description: `${linkResponse}`,
                        title: 'Use this link in a private browsing window, and log in as yourself.',
                        yesButtonText: 'Copy To Clipboard',
                    }
                    const dialogRef: MatDialogRef<ConfirmComponent> = this.dialogs.open(ConfirmComponent, dialogConfig, DialogTemplateType.medium)
                    dialogRef.afterClosed()
                        .pipe(
                            filter(result => !!result),
                            tap((result) => {
                                if (result) {
                                    this.clipboard.copy(linkResponse)
                                }
                            }),
                        )
                        .subscribe()
                }),
            ).subscribe()
    }

    requestPlaidBalance(): void {
        this.plaidLoading = true

        this.paymentAccountService.getPlaidBankAccountBalance(this.businessDetail.id)
            .pipe(
                tap(res => {
                    this.plaidBalance = res
                    this.plaidLoading = false
                }),
                catchError(err => {
                    this.plaidLoading = false
                    return this.dialogs.error(err)
                }),
            )
            .subscribe()
    }

    private getAndSetBusiness(): void {
        this.route.params
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap(() => {
                    return this.business.getBusiness(this.route.snapshot.params.businessId)
                        .pipe(
                            tap(biz => {
                                this.businessDetail = biz
                                this.canRetryPayment = biz.canRetrySubscriptionCharges
                                this.users.next(this.businessDetail.users.map(user => {
                                    return {
                                        name: `${user.profile.firstName} ${user.profile.lastName}`,
                                        email: user.canonicalUsernamesCSV,
                                        roles: user.roles,
                                        dateJoined: user.dateJoined,
                                        getImpLink: 'link',
                                        id: user.profile.id,
                                    }
                                }))
                                this.paymentAccounts.next(this.businessDetail.paymentAccounts.map(account => this.getPaymentAccountRow(account)))
                                this.kycDocs.next(this.businessDetail.kycDocuments)
                                this.invoices.next(this.businessDetail.invoices.map(inv => {
                                    return {
                                        ...inv,
                                        clientColumn: { label: inv.client, value: this.urls.route.businessDetails(inv.clientId) },
                                        vendorColumn: { label: inv.vendor, value: this.urls.route.businessDetails(inv.vendorId) },
                                        invoiceNumberColumn: { label: inv.invoiceNumber, value: this.urls.route.clientInvoiceDetails(inv.invoiceId) },
                                    }
                                }))
                                this.owners.next(this.businessDetail.beneficialOwners.map(owner => this.getOwnerRow(owner)))
                            }),
                            tap(() => this.businessFunction.setValue(this.businessDetail?.businessType.toLowerCase())),
                            filter(biz => this.isClient),
                            switchMap(biz => this.business.getPendingReleaseTotal(biz.id)),
                            tap(total => this.pendingTotal = total),
                        )
                }),
            )
            .subscribe()
    }

    private getBusiness(updatePaymentAccounts?: boolean): void {
        this.business.getBusiness(this.route.snapshot.params.businessId)
            .pipe(
                tap(biz => {
                    this.businessDetail = biz
                    if (updatePaymentAccounts) {
                        this.paymentAccounts.next(this.businessDetail.paymentAccounts.map(account => this.getPaymentAccountRow(account)))
                    }
                }),
            )
            .subscribe()
    }

    private getOwnerRow(owner: BeneficialOwner): BeneficialOwnerRow {
        return {
            ...owner,
            displayAddress: this.addresses.getAddressString(owner.address),
            name: `${owner.firstName} ${owner.lastName}`,
            ssn: owner.maskedTaxId,
        }
    }

    private getPaymentAccountRow(account: AdminPaymentAccount): PaymentAccountRow {
        return {
            ...account,
            name: account.name || 'NA',
            activeIndicator: account.deactivatedBy === 'NA' ? 'activity' : undefined,
            unmaskAccountNumbers: account.paymentAccountType === PaymentAccountType.LiquidBankAccount || account.paymentAccountType === PaymentAccountType.OtherBankAccount || account.paymentAccountType === PaymentAccountType.PlaidBank ? ' ' : undefined,
            last4: this.formatMaskedValue(account.last4),
            routingNumberAbaLast4: account.routingNumberAbaLast4 ? this.formatMaskedValue(account.routingNumberAbaLast4) : undefined,
        }
    }

    private maskFullValue(value?: string): string {
        if (!value) {
            return '--- ---'
        }

        return value.length >= 4 ? `*****${value.slice(-4)}` : '****'
    }
}
