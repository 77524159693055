import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, } from 'rxjs/operators'

import { OrganizationRole } from '../../auth/models'
import { OtherBankAccountRequest } from '../../country-bank-account-config/models/other-bank-account-request.interface'
import { PagedSet, PageRequest } from '../../table/models/'
import { AdminBusiness, AdminBusinessDetail, AdminBusinessProperties, AdminKycDocument, AdminPaymentAccount, BeneficialOwner, BeneficialOwnerRequest, KycDocument, KycDocumentRequest, KycUploadResponse, PaymentAccountDto } from '../models'
import { MicrodepositVerificationRequest } from '../models/microdeposit-verification-request.interface'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessStore {
    private readonly addDummyUrl: string = this.urls.api.dummyAccount()
    private readonly businessUrl: string = this.urls.api.businesses()
    private readonly businessRolesUrl: string = this.urls.api.roles()
    private readonly flagsUrl: string = this.urls.api.businessFlags()
    private readonly foreignPendingUrl: string = this.urls.api.foreignPendingAccounts()
    private readonly foreignBankReviewUrl: string = this.urls.api.foreignBankReviewAccounts()
    private readonly manualUSBankAccountsUrl: string = this.urls.api.manualUSBankAccounts()
    private readonly ownerUrl: string = this.urls.api.owner()
    private readonly partialBusinessUrl: string = this.urls.api.partialBusiness()
    private readonly kycUrl: string = this.urls.api.kycDoc()
    private readonly unmaskFieldUrl: string = this.urls.api.businessUnmaskField()
    private readonly unmaskPaymentAccountFieldUrl: string = this.urls.api.businessUnmaskPaymentAccountField()

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    addDummyAccount(businessId: string): Observable<void> {
        return this.http.post<void>(this.addDummyUrl,
            {
                organizationId: businessId,
            },
        )
    }

    createKycDoc(req: KycDocumentRequest): Observable<AdminKycDocument> {
        return this.http.post<AdminKycDocument>(this.kycUrl, req)
    }

    createManualUSBankAccount(request: OtherBankAccountRequest): Observable<AdminPaymentAccount> {
        return this.http.post<AdminPaymentAccount>(this.manualUSBankAccountsUrl, request)
    }

    createOwner(req: BeneficialOwnerRequest): Observable<BeneficialOwner> {
        return this.http.post<BeneficialOwner>(this.ownerUrl, req)
    }

    createPartialBusiness(request: any): Observable<any> {
        return this.http.post<any>(this.partialBusinessUrl, request)
    }

    deleteOwner(ownerId: string): Observable<void> {
        return this.http.delete<void>(this.urls.api.deleteOwner(ownerId))
    }

    downloadDoc(doc: KycDocument): Observable<boolean> {
        return this.http.get(this.urls.api.downloadDoc(doc), { observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    FileSaver.saveAs(response.body, doc.originalFilename)
                    return true
                }),
            )
    }

    get(req: PageRequest): Observable<PagedSet<AdminBusiness>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<AdminBusiness>>(this.businessUrl, { params })
    }

    getBusiness(id: string): Observable<AdminBusinessDetail> {
        return this.http.get<AdminBusinessDetail>(this.urls.api.businessById(id))
    }

    subscriptionBillingRetry(organizationId: string): Observable<any> {
        return this.http.post<any>(this.urls.api.subscriptionBillingRetries(), { organizationId })
    }

    getForeignPendingAccounts(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<PaymentAccountDto>>(this.foreignPendingUrl, { params })
    }

    getPaymentAccountsForReview(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<PaymentAccountDto>>(this.foreignBankReviewUrl, { params })
    }

    getPendingReleaseTotal(orgId: string): Observable<number> {
        return this.http.get<number>(this.urls.api.pendingReleaseTotal(orgId))
    }

    getRoles(): Observable<OrganizationRole[]> {

        return this.http.get<OrganizationRole[]>(this.businessRolesUrl)
    }

    getUnreviewedMicrodepositVerificationRequests(req: PageRequest): Observable<PagedSet<MicrodepositVerificationRequest>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<MicrodepositVerificationRequest>>(this.urls.api.unreviewedMicrodepositVerificationRequests(), { params })
    }

    getUnverifiedLiquidAccounts(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<PaymentAccountDto>>(this.urls.api.unverifiedLiquidAccounts(), { params })
    }

    unmaskField(businessId: string, fieldName: Extract<keyof AdminBusinessDetail, string>, message: string): Observable<string> {
        return this.http.post(this.unmaskFieldUrl,
            {
                organizationId: businessId,
                fieldName,
                message,
            },
            { responseType: 'text' })
    }

    unmaskPaymentAccountFields(businessId: string, paymentAccountId: string, paymentAccountType: string, fieldNames: Array<Extract<keyof AdminPaymentAccount, string>>, message: string): Observable<{ [key: string]: string }> {
        return this.http.post<{ [key: string]: string }>(this.unmaskPaymentAccountFieldUrl,
            {
                organizationId: businessId,
                paymentAccountId,
                paymentAccountType,
                fieldNames,
                message,
            })
    }

    unmaskPaymentOtherBankAccountFields(businessId: string, paymentAccountId: string, fieldNames: Array<string>, message: string): Observable<{ [key: string]: string }> {
        return this.http.post<{ [key: string]: string }>(this.unmaskPaymentAccountFieldUrl,
            {
                organizationId: businessId,
                paymentAccountId,
                fieldNames,
                message,
            })
    }

    updateBusinessProperties(req: AdminBusinessProperties): Observable<AdminBusinessDetail> {
        return this.http.put<AdminBusinessDetail>(this.urls.api.businessProperties(), req)
    }

    uploadKycDoc(businessId: string, doc: FormData, originalFilename: string): Observable<KycUploadResponse> {
        return this.http.post<KycUploadResponse>(this.urls.api.kycUpload(businessId, originalFilename), doc)
    }

    updateFlags(businessId: string, isTestAccount: boolean, quickBooksEnabled: boolean, customContractsEnabled: boolean, customFXEnabled: boolean, manualUSBankAccountEnabled: boolean,
        internationalClient: boolean, internal: boolean, fake: boolean, archive: boolean,
        message: string): Observable<AdminBusiness> {
        return this.http.post<AdminBusiness>(this.flagsUrl,
            {
                organizationId: businessId,
                isTestAccount,
                quickBooksEnabled,
                customContractsEnabled,
                customFXEnabled,
                manualUSBankAccountEnabled,
                internationalClient,
                internal,
                fake,
                archive,
                message,
            })
    }

    updateFXRate(businessId: string, newRate: number): Observable<number> {
        return this.http.post<number>(this.urls.api.customFXRate(), { orgId: businessId, fxRate: newRate })
    }

    updateOwner(req: BeneficialOwnerRequest): Observable<BeneficialOwner> {
        return this.http.put<BeneficialOwner>(this.ownerUrl, req)
    }

    setMicrodepositVerificationRequestDecision(id: string, releaseDecision: boolean): Observable<void> {
        return this.http.patch<any>(this.urls.api.unreviewedMicrodepositVerificationRequests(), {
            achMicrodepositVerificationId: id,
            release: releaseDecision,
        })
    }

    verifyOtherAccount(businessId: string, paymentAccountId: string, status: string, message: string): Observable<PaymentAccountDto> {
        return this.http.put<PaymentAccountDto>(this.urls.api.verifyOtherAccount(),
            {
                organizationId: businessId,
                paymentAccountId,
                status,
                message,
            })
    }
}
