import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

import { Tab } from '../models'

@Injectable({
    providedIn: 'root',
})
export class TabService {

    private supportTabs: Array<Tab> = [
        {
            label: 'Support',
            route: 'support',
        },
    ]

    private adminTabs: Array<Tab> = [
        {
            label: 'Home',
            route: '/',
        },
        {
            label: 'Account Management',
            route: 'account-management',
        },
        {
            label: 'Businesses',
            route: 'businesses',
        },
        {
            label: 'Invoices',
            route: 'clientInvoices',
        },
        {
            label: 'Users',
            route: 'users',
        },
        {
            label: 'Escrow',
            route: 'escrow',
        },
        {
            label: 'Currency Tool',
            route: 'currencies',
        },
        {
            label: 'FX Tools',
            route: 'fx',
        },
        {
            label: 'Banks',
            route: 'banks',
        },
        {
            label: 'Notifications',
            route: 'notifications',
        },
        ...this.supportTabs,
        {
            label: 'Maintenance',
            route: 'maintenance',
        },
    ]

    tabs$: BehaviorSubject<Array<Tab>> = new BehaviorSubject<Array<Tab>>([])

    constructor(
        private router: Router,
    ) { }

    initAdminTabs(): void {
        this.tabs$.next(this.adminTabs)
    }

    initSupportTabs(): void {
        this.tabs$.next(this.supportTabs)
    }

    addTab(tab: Tab): void {
        const newTabs: Array<Tab> = this.tabs$.getValue()
        newTabs.push(tab)
        this.tabs$.next(newTabs)
        this.router.navigateByUrl(tab.route)
    }

    removeTab(index: number): void {
        const tabs: Array<Tab> = this.tabs$.getValue()
        tabs.splice(index, 1)
        this.tabs$.next(tabs)
        if (tabs.length > 0) {
            this.router.navigateByUrl(tabs[tabs.length - 1].route)
        }

    }
}
