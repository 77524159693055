import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { PagedSet, PageRequest } from '../../table/models'
import { AdminEscrowClientInvoice } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class EscrowStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    getPausedPayouts(pageRequest: PageRequest): Observable<PagedSet<AdminEscrowClientInvoice>> {
        let params: HttpParams = new HttpParams()
        params = params.appendAll({
            page: String(pageRequest.page),
            pageSize: String(pageRequest.pageSize),
            sortString: pageRequest.sortBy,
        })

        return this.http.get<PagedSet<AdminEscrowClientInvoice>>(this.urls.api.escrowPaused(), { params })
    }

    releasePayoutForClientInvoice(invoiceId: string): Observable<boolean> {
        return this.http.put<boolean>(this.urls.api.escrowRelease(invoiceId), {})
    }
}
