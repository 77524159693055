import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'lqd-edit-fx-rate-dialog',
    templateUrl: './edit-fx-rate-dialog.component.html',
    styleUrls: ['./edit-fx-rate-dialog.component.scss']
})
export class EditFxRateDialogComponent implements OnInit {

    fxRateControl: FormControl = new FormControl(undefined, [Validators.required, Validators.min(0), Validators.max(0.03)])
    constructor(
        @Inject(MAT_DIALOG_DATA) data: { rate: number },
        public dialogRef: MatDialogRef<EditFxRateDialogComponent>,
    ) {
        this.fxRateControl.setValue(data.rate)
    }

    ngOnInit(): void {
    }

    close(): void {
        this.dialogRef.close(this.fxRateControl.value)
    }

}
