import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models'
import { AdminEscrowClientInvoice } from '../models'

import { EscrowStore } from './escrow.store'

@Injectable({
    providedIn: 'root',
})
export class EscrowService {

    constructor(
        private dialogs: DialogService,
        private store: EscrowStore,
    ) { }

    getPausedPayouts(pageRequest: PageRequest): Observable<PagedSet<AdminEscrowClientInvoice>> {
        return this.store.getPausedPayouts(pageRequest)
            .pipe(
                take(1),
                catchError(err => {
                    return this.dialogs.error(err)
                }),
            )
    }

    releasePayoutForClientInvoice(invoiceId: string): Observable<boolean> {
        return this.store.releasePayoutForClientInvoice(invoiceId)
            .pipe(
                take(1),
                catchError(err => {
                    return this.dialogs.error(err)
                }),
            )
    }
}
