import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class LiquidAppService {

    // this service is not necessary for reef only declared here in order to support code reuse

    constructor(
    ) { }

    selectedOrganization: any
}
