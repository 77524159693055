<mat-card>

    <mat-card-header>
        <mat-card-title>
            <h4>
                Associated Information
            </h4>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <h5>Invoice Processing Events</h5>
        <app-table
            [id]="processingEventsTableId"
            class="data-table"
            [noStick]="true"
            [defaultSort]="{ active: 'actionDate', direction: 'asc' }"
            [columns]="processingEventsColumns"
            [data$]="processingEventsRows$"
            [length]="processingEventsLength"
            [customPaging]="true"
            [persistPage]="true"
            readonlyModeContainerClasses="overflow-x-scroll"
            (pageChange)="onProcessingEventsPageChange($event)"
        ></app-table>
    </mat-card-content>

    <mat-card-content>

        <h5>Individual Transaction Details from JPM</h5>

        <app-table
            [id]="jpmTxLogsId"
            class="data-table"
            [noStick]="true"
            [columns]="jpmTxColumns"
            [length]="invoiceDetails.jpmTransactionDetails.length"
            [defaultSort]="{ active: 'received', direction: 'asc' }"
            [data$]="jpmTransactionDetails$"
            readonlyModeContainerClasses="overflow-x-scroll"
            (rowClick)="jpmTxRowClick($event)"
        ></app-table>

    </mat-card-content>

    <mat-card-content>

        <h5>Liquid Transaction Instructions</h5>

        <app-table
            [id]="tntDetailsTableId"
            class="data-table"
            [noStick]="true"
            [columns]="tntDetailsColumns"
            [length]="invoiceDetails.payouts.length"
            [defaultSort]="{ active: 'sentOnDate', direction: 'asc' }"
            [data$]="trackAndTraces$"
            (checkButtonClick)="onCheckButtonClick($event)"
            readonlyModeContainerClasses="overflow-x-scroll"
        ></app-table>

    </mat-card-content>

    <mat-card-content>

        <h5>Client to Vendor Latest 50 Invoices</h5>

        <app-table
            [id]="clientVendorHistoryId"
            class="data-table"
            [noStick]="true"
            [columns]="invoiceHistoryColumns"
            [length]="invoiceDetails.clientHistory.length"
            [defaultSort]="{ active: 'date', direction: 'desc' }"
            [data$]="clientVendorHistory$"
            readonlyModeContainerClasses="overflow-x-scroll"
        ></app-table>

    </mat-card-content>

    <mat-card-content>

        <h5>Vendor History</h5>

        <div class="data">
            <div class="subtitle1">Average Invoice Amount</div>
            <div>{{invoiceDetails.vendorAverageInvoiceAmount | currency}}</div>
        </div>

        <div class="data">
            <div class="subtitle1">Count of Invoices Paid to Vendor</div>
            <div>{{invoiceDetails.vendorPaidInvoicesCount}}</div>
        </div>

        <app-table
            [id]="vendorHistoryId"
            class="data-table"
            [noStick]="true"
            [columns]="invoiceHistoryColumns"
            [length]="invoiceDetails.vendorHistory.length"
            [defaultSort]="{ active: 'date', direction: 'desc' }"
            [data$]="vendorHistory$"
            readonlyModeContainerClasses="overflow-x-scroll"
        ></app-table>

    </mat-card-content>

    <lqd-logs
        [id]="transactionLogId"
        [logs]="invoiceDetails.adminLogs"
    ></lqd-logs>

    <mat-card-content>

        <h5>Client History</h5>

        <div class="data">
            <div class="subtitle1">Average Invoice Amount</div>
            <div>{{invoiceDetails.clientAverageInvoiceAmount | currency}}</div>
        </div>

        <div class="data">
            <div class="subtitle1">Count of Unique Vendors Paid</div>
            <div>{{invoiceDetails.clientVendorsPaidCount}}</div>
        </div>

        <app-table
            [id]="clientHistoryId"
            class="data-table"
            [noStick]="true"
            [columns]="invoiceHistoryColumns"
            [length]="invoiceDetails.clientHistory.length"
            [defaultSort]="{ active: 'date', direction: 'desc' }"
            [data$]="clientHistory$"
            readonlyModeContainerClasses="overflow-x-scroll"
            (rowClick)="onRowClick($event)"
        ></app-table>

    </mat-card-content>

</mat-card>